
$size-mobile: 700px
$size-tablet: 900px
$size-desktop: 1200px
$size-desktop-xl: 1800px

$sizes: ("mobile": $size-mobile, "tablet": $size-tablet, "desktop": $size-desktop, "desktop-xl": $size-desktop-xl)

@mixin media($list, $down: true)
    @if length($list) == 2
        @media (min-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1))) and (max-width: if(type-of(nth($list, 2)) == "string", map-get($sizes, nth($list, 2)), nth($list, 2)))
            @content
    @else 
        @if $down
            @media (max-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1)))
                @content
        @else
            @media (min-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1)))
                @content

    
.courses-wrapper
    padding-top: 50px
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: 30px
    row-gap: 30px
    width: 100%
    max-width: 1300px
    margin: 0 auto
    .course-item
         border: 1px solid rgb(79 70 229 / 24%)
         width: -webkit-fill-available
         padding: 29px
         border-radius: 25px
         background-color: rgb(255 255 255 / 60%)
         .course-users
            display: flex
            align-items: center
            .course-user
                position: relative
                width: 32px
                height: 32px
                border-radius: 100px
                background-color: grey
            
.forgot-password
    font-size: 14px
    margin-bottom: 32px
    padding-top: 12px
    .label
        margin-right: 10px

.password-reset-info
    padding: 12px
    background-color: #d9ecff
    border: 1px solid #bdd8ed
    border-radius: 5px
    color: #0984e3
    margin-bottom: 20px

.password-reset-success
    padding: 12px
    background-color: #c8fbf1
    border: 1px solid #9fdfd2
    border-radius: 5px
    color: #00b894
    margin-bottom: 20px

.header-navbar
    display: flex
    align-items: center
    border-bottom: 1px solid rgba(0,0,0,.2)
    padding: 2px 20px 0
    .navbar-logo
        font-size: 24px
        color: blue
        text-decoration: none
        font-weight: 600
    .nav-wrapper
        margin-left: 50px
        display: flex
        align-items: center
        flex-grow: 1
        height: 100%
        .navbar-link
            display: flex
            align-items: center
            margin-right: 30px
            height: 54px
            text-decoration: none
            font-weight: 500
            font-size: 16px
            line-height: 16px
            color: rgb(40,40,40)
            transition: .2s ease
            border-bottom: 2px solid transparent
            &:hover
                color: rgb(80,80,80)
            &:last-of-type
                margin-right: 0px
            &.active
                color: rgb(100 102 241)
                border-bottom-color: rgb(100 102 241)

.layout-custom
   display: flex
   flex-direction: column
   min-height: 100vh


.footer-navbar
    display: flex
    flex-direction: row
    align-items: center
    border-top: 1px solid rgba(0,0,0,.2)
    padding: 20px 0px 20px 0px
    margin-top: auto
    justify-content: space-around
    a
        text-decoration: none
        color: black

.bg-navbar
    background-color: #ffffff52

.whatsapp
    .ant-float-btn-icon
        width: auto!important

#whatsapp
    svg
        fill: #41bf50

.hero-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    padding: 60px 0 40px
    .left-side
        width: 48%
    .right-side
        width: 48%
    .image
        width: 100%
    @include media("tablet")
        flex-direction: column
        .left-side
            width: 100%
        .right-side
            margin-top: 40px
            width: 100%
        