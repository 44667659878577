/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;

  /* --tw-bg-opacity: 1;
  background-color: rgb(
    243 244 246 / var(--tw-bg-opacity)
  ); */
  /* background-color: rgb(243 244 246 / var(--tw-bg-opacity)); bg-gray-100 */
  /* background-color: rgb(229 231 235 / var(--tw-bg-opacity)); bg-gray-200 */
  /* background-color: rgb(209 213 219 / var(--tw-bg-opacity)); bg-gray-300 */

  background: linear-gradient(
    -45deg,
    #ff80b553,
    #9189fc4c,
    #23a5d556,
    #23d5ab48
  );
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
}

.background-animation {
  background: linear-gradient(
    -45deg,
    #ff80b553,
    #9189fc4c,
    #23a5d556,
    #23d5ab48
  );
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  /* height: 100%; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.hero-stats {
  border: 1px solid rgb(79 70 229 / 24%);
  width: -webkit-fill-available;
  padding: 29px;
  border-radius: 25px;
  background-color: #ffffff52;
}

.StatsCounter {
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
  color: #333;
  animation-name: count-up;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.StatsCounter__number {
  animation-name: fade-in;
  animation-duration: 0.5s;
}

@keyframes count-up {
  0% {
    font-size: 0;
  }

  100% {
    font-size: 4rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*::-webkit-scrollbar {
  padding: 2px;
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #e6e8ec;
}

/* *::-webkit-scrollbar-thumb {
  background-color: #5850ec;
  border-radius: 16px;
  border: 1px solid #ffffff;
} */

/* .hide-scroll-bar::-webkit-scrollbar {
  width: 1em;
  height: 5px;
}

.hide-scroll-bar::-webkit-scrollbar-track {
  background: #e6e8ec;
}

.hide-scroll-bar::-webkit-scrollbar-thumb {
  width: 1px;
  height: 10px;
  background-color: #5850ec;
  border-radius: 16px;
  border: 1px solid #ffffff;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Eudoxus Sans', sans-serif;
  scroll-behavior: smooth;
}

.gradient-01 {
  background-image: linear-gradient(
    270deg,
    hsl(295deg 76% 51%) 0%,
    hsl(284deg 70% 73%) 26%,
    hsl(257deg 70% 86%) 39%,
    hsl(202deg 92% 90%) 50%,
    hsl(215deg 77% 81%) 61%,
    hsl(221deg 73% 70%) 74%,
    hsl(220deg 76% 51%) 100%
  );
  filter: blur(125px);
}

.hero-gradient {
  background: linear-gradient(
    97.86deg,
    #a509ff 0%,
    #34acc7 53.65%,
    #a134c7 100%
  );
}

.gradient-02 {
  position: absolute;
  width: 200px;
  height: 438px;
  top: 0px;
  right: 0px;

  background: #7aebfb;
  filter: blur(190px);
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gradient-03 {
  position: absolute;
  width: 404px;
  height: 800px;
  left: 20%;
  top: 5%;

  background: rgba(149, 66, 232, 0.35);
  filter: blur(175px);
  transform: rotate(-114.2deg);
}

.gradient-04 {
  position: absolute;
  width: 304px;
  height: 100vh;
  left: 30%;
  top: 10%;

  background: rgba(45, 72, 152, 0.75);
  filter: blur(200px);
  transform: rotate(-53.13deg);
}

.gradient-05 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.feedback-gradient {
  position: absolute;
  width: 200px;
  height: 316px;
  left: 0px;
  top: 0px;

  background: #8d39a3;
  filter: blur(175px);
}

.footer-gradient {
  position: absolute;
  width: 500px;
  height: 100px;
  left: 37%;
  bottom: 0;

  background: #65c4fa;
  filter: blur(150px);
}
